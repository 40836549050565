@use "sass:math";
@use '@THC/styles/color';

@use '@THC/styles/modifiers';

@use '@THC/styles/core';

@use '~@THC/styles/_breakpoints' as breakpoints;

@import '/utilities/styles/common.scss';


$video-poster-orientation-left: var(--thc-fluid-4-16) 0 0 var(--thc-fluid-4-16);
$video-poster-orientation-top: var(--thc-fluid-4-16) var(--thc-fluid-4-16) 0 0;
$video-poster-orientation-right: 0 var(--thc-fluid-4-16) var(--thc-fluid-4-16) 0;
$video-poster-orientation-bottom: 0 0 var(--thc-fluid-4-16) var(--thc-fluid-4-16);
$video-poster-orientation-all: var(--thc-fluid-4-16);

.videoPoster {
	[data-thc-hyperlink] {
		&[data-orientation='left'] {
			--mw-lp-video-poster-border-radius-internal: #{$video-poster-orientation-left};
		}

		&[data-orientation='top'] {
			--mw-lp-video-poster-border-radius-internal: #{$video-poster-orientation-top};
		}

		&[data-orientation='right'] {
			--mw-lp-video-poster-border-radius-internal: #{$video-poster-orientation-right};
		}

		&[data-orientation='bottom'] {
			--mw-lp-video-poster-border-radius-internal: #{$video-poster-orientation-bottom};
		}

		&[data-orientation='all'] {
			--mw-lp-video-poster-border-radius-internal: #{$video-poster-orientation-all};
		}

		border-radius: var(--mw-lp-video-poster-border-radius, var(--mw-lp-video-poster-border-radius-internal));

		display: unset;

		@include breakpoints.minMobile {
			display: flex;
		}
	}

	.poster {
		border-radius: var(--mw-lp-video-poster-border-radius, var(--mw-lp-video-poster-border-radius-internal));
		color: inherit;
		overflow: hidden;
		padding: 0;
		position: relative;
		aspect-ratio: 1/1;

		@include breakpoints.minMobile {
			aspect-ratio: unset;
		}

		&::after {
			@include modifiers.feedbackLayer();
		}

		&:hover {
			--thc-feedback-layer-background-color: var(--thc-color-lighten5);
		}

		&:active {
			--thc-feedback-layer-background-color: var(--thc-color-darken10);
		}

		.playButton {
			background-color: color.$dark_grey_1;
			border-radius: 100%;
			box-shadow: var(--thc-effect-box-shadow-medium);
			display: block;
			height: core.$space6 * 2;
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			transform-origin: center;
			transition: 300ms background-color;
			width: core.$space6 * 2;
			z-index: 1;

			svg {
				height: var(--thc-icon-large);
				transform: translate(10%, 45%);
			}
		}

		img {
			display: block;
			height: 100%;
			object-fit: cover;

			@include breakpoints.minMobile {
				height: auto;
			}
		}

		img,
		[data-poster-image] {
			transition: 300ms transform;
		}

		@media (hover: hover) {
			&:hover {
				img,
				[data-poster-image] {
					transform: scale(1.05);
				}

				.playButton {
					background-color: color.$dark_grey_2;
				}
			}
		}
	}
}
