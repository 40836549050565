@use "sass:math";
@use '~@THC/styles/color';

@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

div.signupBarWrapper {
	background-color: $dark_grey_1;
	overflow: hidden;
	position: relative;

	@media (min-width: $breakpointTabletMobile) {
		&::before {
			background-color: $natural_black;
			content: '';
			display: block;
			height: 200%;
			left: 158px;
			position: absolute;
			top: -50%;
			transform: rotate(30deg);
			width: 128px;
		}
	}

	section.signupBar {
		overflow: visible;
		padding-bottom: $marginGeneric * 6;
		padding-top: $marginGeneric * 6;
		position: relative;

		& > div {
			align-items: center;
			display: flex;
			flex-direction: column;
			margin: 0 auto;
		}

		h4 {
			@include title2;

			margin-bottom: $marginGeneric * 6;
		}

		.buttonContainer {
			max-width: $marginGeneric * 41; // 328px
		}

		@media (min-width: $breakpointTabletMobile) {
			padding-bottom: $marginGeneric * 11.25;
			padding-top: $marginGeneric * 11.25;

			& > div {
				max-width: $marginGeneric * 86;
			}

			h4 {
				margin-bottom: $marginGeneric * 3.5;
			}
		}
	}

	.titleTablet {
		display: none;
	}

	@media (min-width: $breakpointTabletMobile) {
		.titleMobile {
			display: none;
		}

		.titleTablet {
			display: block;
		}
	}

	&.newSignupBarSection {
		background-color: color.$lighten5;
	}
}

