@use "sass:math";
@use '~@THC/styles/color';

@use '~@THC/styles/core' as core;

@use '~@THC/styles/_breakpoints' as breakpoints;

@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

@import '/utilities/styles/common.scss';

@import '/utilities/styles/containers.scss';

$zorder-chevron: -2;
$zorder-vehicle: -1;
$zorder-heroblock: 100;
$zorder-uspblock: 200;

.contentWrapper {
	@include bigChevron();

	background-color: $dark_grey_1;
	overflow: hidden;
	padding-bottom: $marginGeneric * 5;

	&::before,
	&::after {
		background-color: $natural_black;
		content: none;
		z-index: $zorder-chevron;
	}

	@media (min-width: $mqBreakpointTablet) {
		padding-top: $marginGeneric * 10;

		&::before,
		&::after {
			content: '';
			left: calc(50% + 296px);
			width: $marginGeneric * 22.5; // 180px
		}
	}

	@media (min-width: $breakpointContent) {
		margin-top: $marginGeneric * -12;
		padding-top: $marginGeneric * 12;

		&::before,
		&::after {
			content: '';
			left: calc(50% + 536px);
		}
	}
}

.content {
	padding-bottom: 0;
	padding-top: $marginGeneric * 6;
	transition: opacity 0.1s linear;

	@media (min-width: $mqBreakpointTablet) {
		padding-top: $marginGeneric * 10;
	}

	@media (min-width: $breakpointContent) {
		padding-top: $marginGeneric * 8;
	}

	&.hidden {
		opacity: 0.5;
	}

	.heroBlock {
		@include container-base(91);

		position: relative;
		z-index: $zorder-heroblock;

		@media (min-width: $mqBreakpointTablet) {
			padding-bottom: $marginGeneric * 19; // 152px
		}

		@media (min-width: $breakpointContent) {
			padding-bottom: $marginGeneric * 12; // 96px
		}

		h1 {
			@include title2();

			margin-bottom: $marginGeneric * 3;
			margin-left: auto;
			margin-right: auto;
			margin-top: -15px;

			@media (min-width: $mqBreakpointTablet) {
				@include title1();

				max-width: $marginGeneric * 64; // 512px
			}

			@media (min-width: $breakpointContent) {
				@include title1();

				font-size: 56px;
				line-height: 72px;
				max-width: $marginGeneric * 76; // 608px
			}
		}

		h2 {
			@include large();

			line-height: $marginGeneric * 3;
			margin-bottom: $marginGeneric * 5;
		}

		@media (max-width: ($mqBreakpointTablet - 0.02px)) {
			@include bigChevron();

			margin-bottom: $marginGeneric * 4;
			margin-top: 0;
			overflow: visible;
			padding-bottom: $marginGeneric * 31;
			padding-top: 0;

			&::before,
			&::after {
				background-color: $natural_black;
				z-index: $zorder-chevron;
			}

			&::before {
				bottom: 0;
				left: 50%;
				transform: rotate(-60deg);
				transform-origin: bottom left;
				width: 80px;
			}

			&::after {
				inset: auto 50% 0 auto;
				transform: rotate(60deg);
				transform-origin: bottom right;
				width: 80px;
			}
		}

		button {
			max-width: $marginGeneric * 41;

			@media (min-width: $mqBreakpointTablet) {
				max-width: $marginGeneric * 28;
			}

			@media (min-width: $breakpointContent) {
				max-width: $marginGeneric * 41;
			}
		}

		&.newHeroBlock {

			@media (max-width: (breakpoints.$breakpointTabletMobile - 1)) {
				padding-bottom: core.$space * 30;
			}

			@media (min-width: $mqBreakpointTablet) {
				padding-bottom: core.$space * 14;
			}

			@media (min-width: $breakpointContent) {
				padding-bottom: core.$space * 10;
			}

			h2 {
				margin-bottom: core.$space * 7;
				line-height: 28px;

				@media (max-width: (breakpoints.$breakpointTabletMobile - 1)) {
					margin-bottom: core.$space * 4;
				}
			}
		}
	}

	.uspBlocks {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 auto;
		max-width: 360px;
		position: relative;
		text-align: left;
		z-index: $zorder-uspblock;

		li {
			margin-bottom: $marginGeneric * 5;
			position: relative;
			width: 100%;

			h3 {
				@include title5();
			}

			p {
				padding-bottom: 0;
			}

			&::before {
				background-color: $motorway_yellow;
				border-radius: 100%;
				content: '';
				display: block;
				height: $marginGeneric * 5;
				margin-bottom: $marginGeneric * 3;
				width: $marginGeneric * 5;
			}
		}

		@media (min-width: $mqBreakpointTablet) {
			max-width: 688px;

			li {
				width: 336px;
			}
		}

		@media (min-width: $breakpointContent) {
			max-width: unset;

			li {
				width: 248px;
			}
		}

		svg {
			left: $marginGeneric;
			position: absolute;
			top: $marginGeneric;

			:global {
				.motorway-svg-fill {
					fill: $natural_black;
				}
			}
		}
	}

	.newUspBlocks {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 auto;
		max-width: 360px;
		position: relative;
		text-align: left;
		z-index: $zorder-uspblock;

		li {
			margin-bottom: core.$space * 5;
			position: relative;
			width: 100%;

			h3 {
				@include title5();
			}

			p {
				padding-bottom: 0;
			}

			&::before {
				background-color: color.$primary-black;
				border-radius: 5px;
				content: '';
				display: block;
				height: core.$space * 5;
				margin-bottom: core.$space * 3;
				width: core.$space * 5;
			}
		}

		@media (min-width: $mqBreakpointTablet) {
			max-width: 688px;

			li {
				width: 336px;
			}
		}

		@media (min-width: $breakpointContent) {
			max-width: unset;

			li {
				width: 248px;
			}
		}

		svg {
			left: core.$space;
			position: absolute;
			top: core.$space;

			:global {
				.motorway-svg-fill {
					fill: $natural_black;
				}
			}
		}
	}

	.mastheadImage {
		height: 200px;
		position: absolute;
		width: 308px;
		z-index: $zorder-vehicle;

		@media (min-width: $mqBreakpointTablet) {
			height: 208px;
			width: 364px;
		}

		@media (min-width: $breakpointContent) {
			height: 360px;
			width: 600px;
		}
	}

	.mastheadImageLeft {
		bottom: 0;
		right: calc(50% - 24px);

		@media (min-width: $mqBreakpointTablet) {
			bottom: 20px;
			right: calc(50% + 144px);
		}

		@media (min-width: $breakpointContent) {
			bottom: 48px;
			right: calc(50% + 374px);
		}
	}

	.mastheadImageRight {
		bottom: -92px;
		left: calc(50% - 24px);

		@media (min-width: $mqBreakpointTablet) {
			bottom: -80px;
			left: calc(50% + 144px);
		}

		@media (min-width: $breakpointContent) {
			bottom: -160px;
			left: calc(50% + 336px);
		}
	}

	&.newContent {
		padding-top: core.$space6;
		padding-left: 28px;
    padding-right: 28px;

		.mastheadImageLeft {
			bottom: 0;
			right: calc(50% - 24px);
	
			picture {
				height: 189px;
			}
	
			@media (min-width: $mqBreakpointTablet) {
				bottom: 20px;
				right: calc(50% + 144px);

				picture {
					height: 230px;
				}
			}
	
			@media (min-width: $breakpointContent) {
				bottom: 115px;
				right: calc(50% + 328px);

				picture {
					height: 373px;
				}
			}
		}

		.mastheadImageRight {
			bottom: -92px;
			left: calc(50% - 3px);
	
			@media (min-width: $mqBreakpointTablet) {
				bottom: -80px;
				left: calc(50% + 144px);
			}
	
			@media (min-width: $breakpointContent) {
				bottom: -116px;
				left: calc(50% + 335px);
			}

			picture {
				height: 370px;
			}
		}
	}
}
