@use "sass:math";
@use '~@THC/styles/_breakpoints' as breakpoints;

@use '~@THC/styles/core' as core;

@use '~@THC/styles/_color' as color;

@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

@import '/utilities/styles/common.scss';

.anchorLink {
	display: block;
	position: relative;
	top: -$marginGeneric * 10;
	visibility: hidden;

	@media (min-width: breakpoints.$breakpointTabletMobile) {
		top: -$marginGeneric * 6;
	}

	@media (min-width: breakpoints.$breakpointTablet) {
		top: -$marginGeneric * 10;
	}
}

.sectionWrapper {
	background-color: color.$dark-grey-1;
	overflow-x: hidden;

	.section {
		padding-bottom: $marginGeneric * 10;
		padding-top: $marginGeneric * 10;

		@media (min-width: breakpoints.$breakpointTabletMobile) {
			padding-bottom: $marginGeneric * 6;
			padding-top: $marginGeneric * 6;
		}

		@media (min-width: breakpoints.$breakpointTablet) {
			padding-bottom: $marginGeneric * 22;
			padding-top: $marginGeneric * 10;
		}
	}

	h2 {
		@include title2();

		margin-bottom: $marginGeneric * 4;
		text-align: center;

		@media (min-width: breakpoints.$breakpointTabletMobile) {
			margin-bottom: $marginGeneric * 10;
			margin-top: $marginGeneric * 4;
		}

		@media (min-width: breakpoints.$breakpointTablet) {
			margin-bottom: core.$space * 22;
		}

		@media (max-width: (breakpoints.$breakpointTabletMobile - 1)) {
			margin-bottom: $marginGeneric * 11;
		}
	}

	.stepRow {
		display: flex;
		flex-direction: column;
		margin-bottom: $marginGeneric * 9;

		@media (min-width: breakpoints.$breakpointTabletMobile) {
			flex-direction: row;
			margin-bottom: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		@media (min-width: breakpoints.$breakpointTabletMobile) {
			// flip image <=> text on odd rows
			&:nth-child(2n + 2) {
				.rightBlock {
					order: -1;
				}
			}

			// put 8px spacing left to order marker
			&:nth-child(2n + 1) {
				.rightBlock {
					padding-left: 56px;

					& > span {
						left: 8px;
					}
				}
			}
		}

		@media (max-width: breakpoints.$breakpointTablet) {
			.rightBlock {
				& > span {
					display: flex;
					margin-bottom: $marginGeneric * 2;
					position: initial;
				}
			}
		}
	}

	.leftBlock,
	.rightBlock {
		@media (min-width: breakpoints.$breakpointTabletMobile) {
			width: 50%;
		}
	}

	.leftBlock {
		margin-bottom: core.$space * 8;
		position: relative;

		& > div {
			height: 100%;
			position: absolute;

			picture {
				height: 100%;
			}

			img,
			source {
				height: 100%;
				max-width: unset;
				width: auto;
			}
		}

		@media (min-width: breakpoints.$breakpointTabletMobile) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.rightBlock {
		padding-left: $marginGeneric * 6;
		position: relative;

		& > span {
			@include title5();

			align-items: center;
			background-color: color.$primary-yellow;
			border-radius: 50%;
			color: color.$primary-black;
			display: flex;
			height: $marginGeneric * 4;
			justify-content: center;
			left: 0;
			margin-bottom: 0;
			position: absolute;
			right: auto;
			width: $marginGeneric * 4;
		}

		& > h3 {
			@include title3();

			margin-bottom: $marginGeneric * 3;
		}

		& > p {
			@include large();

			line-height: 1.61;
			margin-bottom: 0;
			padding-bottom: 0;
		}

		@media (min-width: breakpoints.$breakpointTablet) {
			& > h3,
			& > p {
				max-width: 440px;
			}
		}

		@media (max-width: (breakpoints.$breakpointTabletMobile - 1)) {
			padding-left: 0;
		}

		&.newRightBlock {
			& > span {
				background-color: color.$primary-blue;
				border-radius: 3px;
				color: color.$primary-white;
				display: inline-flex;
				position: unset;
				margin-right: core.$space3;
			}

			& > h3 {
				display: inline-flex;
				position: relative;
				top: 2px;
			}

			@media (max-width: (breakpoints.$breakpointTabletMobile - 1)) {
				& > span {
					display: flex;
				}

				& > h3 {
					top: 0;
				}
			}
		}
	}

	.stepRow1 {
		@media (max-width: (breakpoints.$breakpointTabletMobile - 1)) {
			margin-bottom: $marginGeneric * 12;

			.leftBlock {
				height: $marginGeneric * 44.5;
				margin-bottom: -$marginGeneric * 8;
				margin-top: -$marginGeneric * 1.5;

				& > div {
					left: calc(50% - $marginGeneric * 27.5);
					transform: rotate(36deg);
				}
			}
		}

		@media (min-width: breakpoints.$breakpointTabletMobile) and (max-width: breakpoints.$breakpointTablet) {
			margin-bottom: $marginGeneric * 14;

			.leftBlock {
				& > div {
					height: $marginGeneric * 45;
					right: $marginGeneric * 5;
					top: $marginGeneric * 2.5;
				}
			}
		}

		@media (min-width: breakpoints.$breakpointTablet) {
			margin-bottom: $marginGeneric * 23;

			.leftBlock {
				& > div {
					height: $marginGeneric * 85;
					right: $marginGeneric * 12;
					top: -$marginGeneric * 13.25;
				}
			}

			.rightBlock {
				height: $marginGeneric * 57;
			}
		}
	}

	.newStepRow1 {
		@media (max-width: (breakpoints.$breakpointTabletMobile - 1)) {
			margin-bottom: core.$space * 8;

			.leftBlock {
				height: 300px;
				top: 12px;
				margin-bottom: 32px;

				& > div {
					left: calc(50% - 253px);
					height: 280px;
				}
			}

			.mobileImage {
				display: block;
			}

			.desktopImage {
				display: none;
			}
		}

		@media (min-width: breakpoints.$breakpointTabletMobile) and (max-width: breakpoints.$breakpointTablet) {
			margin-bottom: 112px;

			.leftBlock {
				& > div {
					height: 360px; 
					right: 40px;   
					top: -28px;   
				}
			}

			.mobileImage {
				display: none;
			}

			.desktopImage {
				display: block;
			}
		}

		@media (min-width: breakpoints.$breakpointTablet) {
			margin-bottom: 184px;

			.leftBlock {
				& > div {
					height: 883px;
					right: 97px;
					top: -260px;
				}
			}

			.rightBlock {
				height: 456px;
			}

			.mobileImage {
				display: none;
			}

			.desktopImage {
				display: block;
			}
		}
	}

	.stepRow2 {
		@media (max-width: (breakpoints.$breakpointTabletMobile - 1)) {
			margin-bottom: $marginGeneric * 11.75;

			.leftBlock {
				height: $marginGeneric * 27;
				margin-bottom: $marginGeneric * 5.75;

				& > div {
					right: calc(50% - $marginGeneric * 31);
				}
			}
		}

		@media (min-width: breakpoints.$breakpointTabletMobile) and (max-width: breakpoints.$breakpointTablet) {
			margin-bottom: $marginGeneric * 14;

			.leftBlock {
				& > div {
					height: $marginGeneric * 32.25;
					left: $marginGeneric * 3;
					top: $marginGeneric * 6.25;
				}
			}
		}

		@media (min-width: breakpoints.$breakpointTablet) {
			margin-bottom: $marginGeneric * 32.75;

			.leftBlock {
				& > div {
					height: $marginGeneric * 64;
					left: $marginGeneric * 3;
					top: -$marginGeneric * 14;
				}
			}

			.rightBlock {
				height: $marginGeneric * 58;
			}
		}
	}

	.newStepRow2 {
		@media (max-width: (breakpoints.$breakpointTabletMobile - 1)) {
			margin-bottom: core.$space * 8;

			.leftBlock {
				height: 293px;
				margin-bottom: core.$space * 8;
				left: 77px;

				& > div {
					right: - core.$space * 11.5;
				}
			}
		}

		@media (min-width: breakpoints.$breakpointTabletMobile) and (max-width: breakpoints.$breakpointTablet) {
			margin-bottom: core.$space * 14;

			.leftBlock {
				& > div {
					height: 258px;
					left: core.$space * 3;
					top: core.$space * 3;
				}
			}
		}

		@media (min-width: breakpoints.$breakpointTablet) {
			margin-bottom: core.$space * 17.5;

			.leftBlock {
				& > div {
					height: 517px;
					left: -30px;
					top: -112px
				}
			}

			.rightBlock {
				height: 464px;
			}
		}
	}

	.stepRow3 {
		@media (max-width: (breakpoints.$breakpointTabletMobile - 1)) {
			margin-bottom: $marginGeneric * 12;

			.leftBlock {
				height: $marginGeneric * 28;
				margin-bottom: 0;

				& > div {
					left: -$marginGeneric * 52.5;
				}
			}
		}

		@media (min-width: breakpoints.$breakpointTabletMobile) and (max-width: breakpoints.$breakpointTablet) {
			margin-bottom: $marginGeneric * 14;

			.leftBlock {
				& > div {
					height: $marginGeneric * 34.5;
					right: $marginGeneric * 2;
					top: $marginGeneric * 3.5;
				}
			}
		}

		@media (min-width: breakpoints.$breakpointTablet) {
			margin-bottom: $marginGeneric * 20;

			.leftBlock {
				& > div {
					height: $marginGeneric * 60;
					right: $marginGeneric * 7;
					top: -$marginGeneric * 16.75;
				}
			}

			.rightBlock {
				height: $marginGeneric * 52;
			}
		}
	}

	.newStepRow3 {
		@media (max-width: (breakpoints.$breakpointTabletMobile - 1)) {
			margin-bottom: core.$space * 8;

			.leftBlock {
				height: 296px;

				& > div {
					height: 298px;
					left: 0;
				}
			}

			.mobileImage {
				display: block;
			}

			.desktopImage {
				display: none;
			}
		}

		@media (min-width: breakpoints.$breakpointTabletMobile) and (max-width: breakpoints.$breakpointTablet) {
			margin-bottom: core.$space * 14;

			.leftBlock {
				& > div {
					height: 385px;
					right: 86px;
					top: -60px;
				}
			}

			.mobileImage {
				display: none;
			}

			.desktopImage {
				display: block;
			}
		}

		@media (min-width: breakpoints.$breakpointTablet) {
			margin-bottom: core.$space * 20;

			.leftBlock {
				& > div {
					height: 595px;
					right: 96px;
					top: -134px;
				}
			}

			.rightBlock {
				height: 416px;
			}

			.mobileImage {
				display: none;
			}

			.desktopImage {
				display: block;
			}
		}
	}

	.stepRow4 {
		@media (max-width: (breakpoints.$breakpointTabletMobile - 1)) {
			margin-bottom: $marginGeneric * 11.25;

			.leftBlock {
				height: $marginGeneric * 29.75;
				margin-bottom: $marginGeneric * 3.25;

				& > div {
					left: calc(50% - $marginGeneric * 18.25);
				}
			}
		}

		@media (min-width: breakpoints.$breakpointTabletMobile) and (max-width: breakpoints.$breakpointTablet) {
			margin-bottom: $marginGeneric * 14;

			.leftBlock {
				& > div {
					height: $marginGeneric * 36.25;
					left: 0;
					top: $marginGeneric;
				}
			}
		}

		@media (min-width: $breakpointContent) {
			margin-bottom: $marginGeneric * 16.25;

			.leftBlock {
				& > div {
					height: $marginGeneric * 70.5;
					left: 0;
					top: -$marginGeneric * 20.75;
				}
			}

			.rightBlock {
				height: $marginGeneric * 58;
				margin-top: 0;
			}
		}
	}

	.newStepRow4 {
		@media (max-width: (breakpoints.$breakpointTabletMobile - 1)) {
			margin-bottom: core.$space * 8;

			.leftBlock {
				height: 277px;
				margin-bottom: core.$space * 8;

				& > div {
					right: -92px;
				}
			}
		}

		@media (min-width: breakpoints.$breakpointTabletMobile) and (max-width: breakpoints.$breakpointTablet) {
			margin-bottom: core.$space * 14;

			.leftBlock {
				& > div {
					height: 290px;
					left: 0;
					top: core.$space;
				}
			}
		}

		@media (min-width: breakpoints.$breakpointTablet) {

			margin-bottom: 46px;

			.leftBlock {
				& > div {
					height: 565px;
					left: core.$space * 2;
					top: -82px;
				}
			}

			.rightBlock {
				height: 464px;
				margin-top: 0;
			}
		}
	}

	.stepRow5 {
		@media (max-width: (breakpoints.$breakpointTabletMobile - 1)) {
			.leftBlock {
				height: $marginGeneric * 27.25;
				margin-bottom: $marginGeneric * 4.75;

				& > div {
					left: calc(50% - $marginGeneric * 36.25);
				}
			}
		}

		@media (min-width: breakpoints.$breakpointTabletMobile) and (max-width: breakpoints.$breakpointTablet) {
			margin-bottom: $marginGeneric * 10 !important;

			.leftBlock {
				& > div {
					height: $marginGeneric * 26.25;
					right: 0;
					top: $marginGeneric * 10;
				}
			}
		}

		@media (min-width: breakpoints.$breakpointTablet) {
			margin-bottom: $marginGeneric * 4.25 !important;

			.leftBlock {
				& > div {
					height: $marginGeneric * 51;
					right: $marginGeneric * 5;
					top: -$marginGeneric * 8.75;
				}
			}

			.rightBlock {
				height: $marginGeneric * 35.75;
				margin-top: 0;
			}
		}
	}

	.newStepRow5 {
		@media (max-width: (breakpoints.$breakpointTabletMobile - 1)) {
			.leftBlock {
				height: 351px;
				margin-bottom: core.$space  * 8;

				& > div {
					left:  -34px;
				}
			}
		}

		@media (min-width: breakpoints.$breakpointTabletMobile) and (max-width: breakpoints.$breakpointTablet) {
			margin-bottom: $marginGeneric * 10 !important;

			.leftBlock {
				& > div {
					height: 336px;
					right: 68px;
					top: - core.$space * 2.5;
				}
			}
		}

		@media (min-width: breakpoints.$breakpointTablet) {
			
			margin-bottom: $marginGeneric * 16 !important;

			.leftBlock {
				& > div {
					height: 537px;
					right: core.$space * 10;
					top: -74px;
				}
			}

			.rightBlock {
				height: 286px;
				margin-top: 0;
				padding-top: $marginGeneric * 8;
			}
		}
	}

	&.newSection {
		background-color: color.$dark-grey-1;

		h2 {
			margin-top: core.$space2;

			@media (max-width: (breakpoints.$breakpointTabletMobile - 1)) {
				margin-bottom: 0;
			}
		}
	}
}
