@use "sass:math";
@use '~@THC/styles/core' as core;

@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

@import '/utilities/styles/common.scss';

.makesCarousel {
	background-color: $natural_black;
	max-width: 100vw; // Prevent storybook rendering wider than the viewport.
	overflow: hidden;
	padding-bottom: $marginGeneric * 5;
	padding-top: $marginGeneric * 6;
	text-align: center;

	h3 {
		@include title3();

		margin-bottom: $marginGeneric * 2;
		padding-bottom: 0;
		padding-top: 0;
	}

	.tabletRows,
	.desktopRows {
		display: none;
	}

	ul {
		display: flex;
		justify-content: center;
		overflow: visible;

		& > li {
			padding: $marginGeneric $marginGeneric * 1.5;
		}
	}

	@media (max-width: ($mqBreakpointTablet - 0.02px)) {
		ul > li > *::before {
			height: $marginGeneric * 11;
			width: $marginGeneric * 11;
		}
	}

	@media (min-width: $mqBreakpointTablet) {
		padding-bottom: $marginGeneric * 9;
		padding-top: $marginGeneric * 10;

		.mobileRows {
			display: none;
		}

		.tabletRows {
			display: block;
		}

		h3 {
			margin-bottom: $marginGeneric * 5;
		}

		ul > li {
			padding: $marginGeneric $marginGeneric * 4;
		}
	}

	@media (min-width: $breakpointContent) {
		.tabletRows {
			display: none;
		}

		.desktopRows {
			display: block;
		}

		ul {
			flex-wrap: nowrap;
		}
	}

	&.newMakesCarousel {
		padding-bottom: core.$space * 6;

		@media (min-width: $breakpointContent) {
			padding-bottom: core.$space * 10;
		}

		ul {
			& > li {
				padding: core.$space * 2 core.$space * 1.5 0;
			}
		}

		@media (min-width: $mqBreakpointTablet) {
			ul > li {
				padding: core.$space core.$space * 4 0;
			}
		}
	}
}
